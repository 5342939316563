import React from 'react';
import { useNavigate } from 'react-router-dom';

type CardProps = {
	id: number;
	name: string;
	description: string;
};

const OrganizationCard: React.FC<CardProps> = ({ id, name, description }) => {
	const navigate = useNavigate();

	const handleCardClick = () => {
		navigate(`/organization/${id}`);
	};

	return (
		<div
			onClick={handleCardClick}
			className="bg-bigger cursor-pointer border-2 border-white rounded-lg shadow-lg p-4 m-2 relative hover:bg-biggerHover"
			data-test={`card-${id}`}
		>
			<h2 className="text-4xl mb-2 text-white font-bigger">{`${name}`}</h2>
			<hr className="mb-2" />
			<p className="text-gray-300">{`${description}`}</p>
		</div>
	);
};

export default OrganizationCard;
