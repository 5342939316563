import { ApiRequestConfig, apiService } from './api.service';

import { Form, ICreateForm } from '@/interfaces/forms/Iforms';
import { IApiService } from '@/interfaces/services/IApiService';
import { IFormService } from '@/interfaces/services/IFormService';

class FormService implements IFormService {
	api: IApiService<ApiRequestConfig>;
	constructor(api: IApiService<ApiRequestConfig>) {
		this.api = api;
	}

	async getForms(config?: ApiRequestConfig): Promise<Form[]> {
		return await this.api.get<Form[]>('/form', config);
	}

	async getFormById(id: number, config?: ApiRequestConfig): Promise<Form> {
		return await this.api.get<Form>(`/form/${id}`, config);
	}

	async getAllByOrganizationId(
		organizationId: number,
		config?: ApiRequestConfig,
	): Promise<Form[]> {
		return await this.api.get<Form[]>(
			`/form/organization/${organizationId}`,
			config,
		);
	}

	async postForm(
		createFormDto: ICreateForm,
		config?: ApiRequestConfig,
	): Promise<Form> {
		return await this.api.post<Form>(`/form`, { ...createFormDto }, config);
	}

	async updateForm(
		id: number,
		updateFormDto: ICreateForm,
		config?: ApiRequestConfig,
	) {
		return await this.api.patch<Form>(
			`/form/${id}`,
			{ ...updateFormDto },
			config,
		);
	}
}

export const fromService = new FormService(apiService);
