import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

type CardProps = {
	controlNumber: number;
	name: string;
};

const Card: React.FC<CardProps> = ({ controlNumber, name }) => {
	const navigate = useNavigate();

	const { organizationId } = useParams<{ organizationId: string }>();

	const handleCardClick = () => {
		navigate(`/organization/${organizationId}/cis-controls/${controlNumber}`);
	};

	return (
		<div
			onClick={handleCardClick}
			className="cursor-pointer border rounded-lg shadow-lg p-4 m-2 hover:bg-gray-200 relative"
			data-test={`card-${controlNumber}`}
		>
			<h2 className="text-4xl mb-2 text-dark font-bigger">{`Control ${controlNumber}`}</h2>
			<hr className="mb-2" />
			<p className="text-gray-800">{`${name}`}</p>
		</div>
	);
};

export default Card;
