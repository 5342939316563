import React from 'react';

import { NextPageButton } from './NextPageButton';
import { PreviousPageButton } from './PreviousPageButton';

export type PaginationControlType = 'previous' | 'next';

interface PaginationControlProps {
	type: PaginationControlType;
	onClick: () => void;
}

export const PaginationControl: React.FC<PaginationControlProps> = ({
	type,
	onClick,
}) => {
	switch (type) {
		case 'previous':
			return <PreviousPageButton onClick={onClick} />;
		case 'next':
			return <NextPageButton onClick={onClick} />;
		default:
			return null;
	}
};
