import React from 'react';

import OrganizationCard from './OrganizationCard';

import { IOrganization } from '@/interfaces/organization/IOrganization';

type OrganizationContainerProps = {
	organizations: IOrganization[];
};

const OrganizationContainer: React.FC<OrganizationContainerProps> = ({
	organizations,
}) => {
	return (
		<>
			<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 max-w-full mx-auto p-4">
				{organizations?.map(
					(organization) =>
						organization.id && (
							<OrganizationCard
								key={organization.id}
								id={organization.id}
								name={organization.name}
								description={organization.description}
							/>
						),
				)}
			</div>
		</>
	);
};

export default OrganizationContainer;
