import React from 'react';

import { BarChartComponent } from './barChartComponent';

interface IImpPercentageCharttProps {
	implementationPercents: { id: number; average: number }[];
}

const controlLabels = [
	'CSC #1',
	'CSC #2',
	'CSC #3',
	'CSC #4',
	'CSC #5',
	'CSC #6',
	'CSC #7',
	'CSC #8',
	'CSC #9',
	'CSC #10',
	'CSC #11',
	'CSC #12',
	'CSC #13',
	'CSC #14',
	'CSC #15',
	'CSC #16',
	'CSC #17',
	'CSC #18',
];

export const ImpPercentageChart: React.FC<IImpPercentageCharttProps> = ({
	implementationPercents,
}) => {
	const controlPercents: (null | number)[] = new Array(18).fill(null);

	implementationPercents.forEach((item) => {
		controlPercents[item.id - 1] = item.average;
	});

	const chartData = {
		labels: controlLabels,
		datasets: [
			{
				data: controlPercents,
				backgroundColor: '#70AD47',
				borderColor: '#70AD47',
				borderWidth: 1,
			},
		],
	};

	return (
		<div
			className="my-8 border border-black flex flex-col items-center w-fit mx-auto"
			data-test="imp-percent-bar-chart"
		>
			<p className="w-full text-center font-bold text-xl pt-4 text-black">
				Implementation Percentage by Control
			</p>
			<div className="p-8">
				<BarChartComponent
					data={chartData}
					width={660}
					id="percent-bar-chart"
				/>
			</div>
		</div>
	);
};
