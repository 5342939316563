import { Link } from 'react-router-dom';

type PropTypes = {
	to: string;
	innerText: string;
};
export default function Button({ to, innerText, ...props }: PropTypes) {
	return (
		<Link
			to={to}
			className="flex rounded-full bg-bigger font-bold text-white m-2 px-2 "
			{...props}
		>
			<p className="pointer-events-none font-bigger text-4xl">{innerText}</p>
		</Link>
	);
}
