import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Control from './Control';

import { IControl } from '@/interfaces/controls/controls';
import { controlService } from '@/services/control.service';

const CisControls = () => {
	const [selectedControl, setSelectedControl] = useState<IControl | null>(null);
	const [error, setError] = useState<string | null>(null);

	const { id } = useParams<{ id: string }>();

	useEffect(() => {
		const controlId = Number(id);

		const fetchControl = async () => {
			try {
				const control = await controlService.getControlById(controlId);
				setSelectedControl(control);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				setError(err.message || 'Failed to fetch control');
			}
		};

		fetchControl();
	}, [id]);

	if (error) {
		return <div>Error: {error}</div>;
	}

	if (selectedControl) return <Control selectedControl={selectedControl} />;
	return null;
};

export default CisControls;
