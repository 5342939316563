export const getEllipsisRange = (
	arr: (number | '...')[],
	ellipsisType: 'first' | 'second',
) => {
	const indexType =
		arr.filter((page) => page === '...').length < 2 ? 'first' : ellipsisType;
	let start = 0;
	let end = 0;
	let prev: number | null = null;
	let ellipsisCount = 0;

	for (let i = 0; i < arr.length; i++) {
		const item = arr[i];

		if (item === '...') {
			ellipsisCount += 1;

			if (ellipsisCount === (indexType === 'first' ? 1 : 2)) {
				if (prev !== null) {
					const next = arr[i + 1] as number;
					if (typeof next === 'number') {
						start = prev + 1;
						end = next - 1;
					}
				}
			}
		} else {
			const current = item as number;
			if (prev !== null && current !== prev + 1) {
				if (ellipsisCount === (indexType === 'first' ? 1 : 2)) {
					start = prev + 1;
					end = current - 1;
				}
			}
			prev = current;
		}
	}

	return { start, end };
};

export const getPageNumbers = (totalPages: number, currentPage: number) => {
	let pages: (number | '...')[] = [];

	if (totalPages <= 7) {
		for (let i = 1; i <= totalPages; i++) {
			pages.push(i);
		}
	} else {
		if (currentPage < 5) {
			pages = [1, 2, 3, 4, 5, '...', totalPages];
		} else if (currentPage > totalPages - 4) {
			pages = [
				1,
				'...',
				totalPages - 4,
				totalPages - 3,
				totalPages - 2,
				totalPages - 1,
				totalPages,
			];
		} else {
			pages = [
				1,
				'...',
				currentPage - 1,
				currentPage,
				currentPage + 1,
				'...',
				totalPages,
			];
		}
	}
	return pages;
};
