import ConfirmUserForm from '@/components/auth/ConfirmUserForm';
import { useAuthProvider } from '@/hooks/auth/useAuthProvider';

export default function ConfirmUser() {
	const { handleConfirmUser, loadingState } = useAuthProvider();
	return (
		<div className="flex-1 bg-white">
			<div className="animate-appear">
				<ConfirmUserForm
					handleSubmit={handleConfirmUser}
					loading={loadingState.confirmUser}
				/>
			</div>
		</div>
	);
}
