import {
	BarController,
	BarElement,
	CategoryScale,
	Chart,
	ChartData,
	LinearScale,
	Tooltip,
} from 'chart.js';
import React, { useEffect } from 'react';

Chart.register(BarController, BarElement, CategoryScale, LinearScale, Tooltip);
Chart.defaults.color = 'black';
const WIDTH_DEFAULT = 500;

interface IChartProps {
	id: string;
	data?: ChartData<'bar', (null | number)[], unknown>;
	width?: number;
}

export const BarChartComponent: React.FC<IChartProps> = ({
	data,
	width,
	id,
}) => {
	useEffect(() => {
		if (!data) return;

		const chart = Chart.getChart(id);
		if (chart) {
			chart.destroy();
		}
		new Chart(id, {
			type: 'bar',
			data: {
				...data,
			},
			options: {
				responsive: true,
				scales: {
					y: {
						max: 100,
					},
				},
				plugins: {
					tooltip: {
						callbacks: {
							label: function (context: any) {
								return context.formattedValue + '%';
							},
						},
					},
				},
			},
		});
	}, [data]);

	if (!data) return <></>;

	return (
		<div
			style={{
				width: width ?? WIDTH_DEFAULT,
			}}
		>
			<canvas id={id}></canvas>
		</div>
	);
};
