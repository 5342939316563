export const handleFormatResponse = (response: string) => {
	const formattedResponse = response.replace(/\s+/g, '-').toLowerCase();
	return formattedResponse;
};

export const handleDeformatResponse = (response: string) => {
	const formattedResponse = response
		.split('-')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');
	return formattedResponse;
};
