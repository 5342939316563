import { IColumn } from '@/interfaces/table/IAttckCapability';
import { IMaturityRating } from '@/interfaces/table/IMaturityRating';

export const MATURITY_RATING_DEFAULT: IMaturityRating[] = [
	{ level: 'Level One', description: 'Policies Complete', score: 0 },
	{ level: 'Level Two', description: 'Controls 1-5 Implemented', score: 0 },
	{ level: 'Level Three', description: 'All Controls Implemented', score: 0 },
	{ level: 'Level Four', description: 'All Controls Automated', score: 0 },
	{ level: 'Level Five', description: 'All Controls Reported', score: 0 },
];

export const MATURITY_RATING_COLUMNS: IColumn<IMaturityRating>[] = [
	{ header: 'Maturity level:', accessor: 'level' },
	{ header: 'Description:', accessor: 'description' },
	{ header: 'Score:', accessor: 'score' },
];
