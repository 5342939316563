import { ChartData } from 'chart.js';
import React, { useEffect, useState } from 'react';

import { ChartComponent } from './ChartComponent';

const EMPTY_DATA_CHART: ChartData<'doughnut', number[], unknown> = {
	labels: ['Empty'],
	datasets: [
		{
			label: 'Risk',
			data: [100],
			hoverOffset: 4,
			backgroundColor: ['#d0cece'],
		},
	],
};

interface IChartRiskProps {
	risk: { addressed: number; accepted: number };
	emptyRisk?: boolean;
	error: string | null;
}

export const ChartRisk: React.FC<IChartRiskProps> = ({
	risk,
	emptyRisk,
	error,
}) => {
	const [dataChart, setDataChart] = useState(
		{} as ChartData<'doughnut', number[], unknown>,
	);

	const setRiskData = async () => {
		if (!emptyRisk) {
			const data = [
				{ risk: 'Addressed', value: risk.addressed },
				{ risk: 'Accepted', value: risk.accepted },
			];

			setDataChart({
				labels: ['Addressed', 'Accepted'],
				datasets: [
					{
						label: 'Risk',
						data: data.map((row) => row.value),
						hoverOffset: 4,
						backgroundColor: ['rgb(0, 255, 0)', '#e74c3c'],
					},
				],
			});
		} else {
			setDataChart(EMPTY_DATA_CHART);
		}
	};

	useEffect(() => {
		setRiskData();
	}, [risk]);

	return (
		<div
			className="my-8 p-8 border rounded flex items-center gap-10 w-fit mx-auto"
			data-test="chart-risk"
		>
			<div className="flex flex-col">
				<div className="mx-auto">
					<ChartComponent data={dataChart} />
				</div>
				{error && (
					<p className="text-red-500 text-center" data-test="chart-risk-error">
						{error}
					</p>
				)}
			</div>
			<div>
				<div className="bg-[#007054] text-white font-semibold flex p-0.5 gap-10 w-[400px] justify-center text-sm">
					<p>Risk addressed:</p>
					<p>{risk.addressed}%</p>
				</div>
				<div className="mt-3 bg-[#e74c3c] text-white font-semibold flex p-0.5 gap-10 w-[400px] justify-center text-sm">
					<p>Risk accepted:</p>
					<p>{risk.accepted}%</p>
				</div>
			</div>
		</div>
	);
};
