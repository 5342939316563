import React from 'react';

import { BarChartComponent } from './barChartComponent';

interface IImpGroupsBarChartProps {
	implementationGroups: { one: number; two: number; three: number };
}

export const ImpGroupsBarChart: React.FC<IImpGroupsBarChartProps> = ({
	implementationGroups,
}) => {
	const chartData = {
		labels: ['Group One', 'Group Two', 'Group Three'],
		datasets: [
			{
				data: [
					implementationGroups.one,
					implementationGroups.two,
					implementationGroups.three,
				],
				backgroundColor: ['#70AD47', '#70AD47', '#70AD47'],
				borderColor: ['#70AD47', '#70AD47', '#70AD47'],
				borderWidth: 1,
			},
		],
	};

	return (
		<div
			className="my-8 border-[1px] border-black flex flex-col items-center text-black"
			data-test="imp-bar-chart"
		>
			<p className="w-full text-center font-bold text-black py-1">
				Implementation Group Scores
			</p>

			<div className="p-8">
				<BarChartComponent data={chartData} id="bar-chart" />
			</div>
		</div>
	);
};
