import { ApiRequestConfig, apiService } from './api.service';

import { IGlobalRisk, IRisk } from '@/interfaces/risk/IRisk';
import { IApiService } from '@/interfaces/services/IApiService';
import { IRiskService } from '@/interfaces/services/IRiskService';

class RiskService implements IRiskService {
	api: IApiService<ApiRequestConfig>;
	constructor(api: IApiService<ApiRequestConfig>) {
		this.api = api;
	}

	async getControlRisk(
		organizationId: number,
		controlId: number,
	): Promise<IRisk> {
		return await this.api.get<IRisk>(
			`/risk/organization/${organizationId}/control/${controlId}`,
		);
	}

	async getGlobalRisk(organizationId: number): Promise<IGlobalRisk> {
		return await this.api.get<IGlobalRisk>(
			`/risk/organization/${organizationId}/global`,
		);
	}
}

export const riskService = new RiskService(apiService);
