import { IAttackControlMapping, IGlobalRisk } from '@/interfaces/risk/IRisk';
import {
	Capability,
	IAttackActivity,
} from '@/interfaces/table/IAttckCapability';

const attackControlMapping: IAttackControlMapping = {
	'Initial Access': {
		preventive: [2, 4, 5, 7, 9],
		detective: [1, 8],
	},
	Execution: {
		preventive: [2, 4, 5, 7],
		detective: [1, 8],
	},
	Persistence: {
		preventive: [2, 4, 5, 7],
		detective: [1, 8],
	},
	'Privilege Escalation': {
		preventive: [2, 4, 5, 7],
		detective: [1, 8],
	},
	'Defense Evasion': {
		preventive: [2, 4, 5, 7],
		detective: [1, 8],
	},
	'Credential Access': {
		preventive: [2, 4, 5, 6, 7],
		detective: [1, 8],
	},
	Discovery: {
		preventive: [2, 4, 5, 6, 7, 13, 14],
		detective: [1, 8],
	},
	'Lateral Movement': {
		preventive: [2, 4, 5, 6, 7, 12, 13],
		detective: [1, 8],
	},
	Collection: {
		preventive: [2, 4, 5, 6, 7, 12, 13],
		detective: [1, 8],
	},
	'Command and Control': {
		preventive: [12, 13],
		detective: [1, 8, 13],
	},
	Exfiltration: {
		preventive: [12, 13],
		detective: [1, 8, 13],
	},
};

const classifyCapability = (average: number): Capability => {
	const normalizedAverage = average / 100;
	if (normalizedAverage >= 0 && normalizedAverage <= 0.25)
		return Capability.Low;
	if (normalizedAverage > 0.25 && normalizedAverage <= 0.75)
		return Capability.Moderate;
	if (normalizedAverage > 0.75 && normalizedAverage <= 1)
		return Capability.High;

	return Capability.Invalid;
};

export const calculateAttckCapabilities = (
	data: IGlobalRisk['globalControlsImplementation'],
): IAttackActivity[] => {
	const result = Object.entries(attackControlMapping).map(
		([property, { preventive, detective }]) => {
			const preventiveControls = data.filter((control) =>
				preventive.includes(control.id),
			);
			const totalPreventive = preventiveControls.reduce(
				(sum, control) => sum + control.average,
				0,
			);
			const averagePreventive = totalPreventive / preventive.length;
			const preventiveRisk = classifyCapability(averagePreventive);

			const detectiveControls = data.filter((control) =>
				detective.includes(control.id),
			);
			const totalDetective = detectiveControls.reduce(
				(sum, control) => sum + control.average,
				0,
			);
			const averageDetective = totalDetective / detective.length;
			const detectiveRisk = classifyCapability(averageDetective);

			return {
				activity: property,
				preventive: preventiveRisk,
				detective: detectiveRisk,
			};
		},
	);

	return result;
};
