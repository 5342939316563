import { ArrowLongRightIcon } from '@heroicons/react/24/solid';
import React from 'react';

interface NextPageButtonProps {
	onClick: () => void;
}

export const NextPageButton: React.FC<NextPageButtonProps> = ({ onClick }) => {
	return (
		<div
			className="group relative flex items-center pt-4 gap-3 cursor-pointer border-t-[1px] decoration-solid"
			onClick={onClick}
			data-test="pagination-next-button"
		>
			<div className="absolute top-[-1px] left-0 h-[2px] w-full group-hover:bg-gray-300 bg-transparent"></div>
			<span className="font-medium text-gray-500 text-sm group-hover:text-gray-700">
				Next
			</span>
			<ArrowLongRightIcon height={20} width={20} className="fill-gray-400" />
		</div>
	);
};
