import { ArcElement, Chart, ChartData, PieController, Tooltip } from 'chart.js';
import React, { useEffect } from 'react';

Chart.register(PieController, ArcElement, Tooltip);
const WIDTH_DEFAULT = 130;

interface IChartProps {
	data?: ChartData<'doughnut', number[], unknown>;
	width?: number;
}

export const ChartComponent: React.FC<IChartProps> = ({ data, width }) => {
	useEffect(() => {
		if (!data) return;

		const chart = Chart.getChart('chart');
		if (chart) {
			chart.destroy();
		}
		new Chart('chart', {
			type: 'doughnut',
			data: {
				...data,
			},
			options: {
				responsive: true,
			},
		});
	}, [data]);

	if (!data) return <></>;

	return (
		<div
			style={{
				width: width ? width : WIDTH_DEFAULT,
			}}
		>
			<canvas id="chart"></canvas>
		</div>
	);
};
