import React from 'react';

import Table from './Table';

import {
	MATURITY_RATING_COLUMNS,
	MATURITY_RATING_DEFAULT,
} from '@/data/table/maturityRatingData';
import { IMaturityRating } from '@/interfaces/table/IMaturityRating';

interface IProps {
	data: IMaturityRating[] | null;
}

export const MaturityRatingTable: React.FC<IProps> = ({ data }) => {
	const [dataMaturity, setDataMaturity] = React.useState(
		MATURITY_RATING_DEFAULT,
	);

	React.useEffect(() => {
		if (data) {
			const parseScoreMaturityRating = data.map((level) => ({
				...level,
				score: Number((level.score / 100).toFixed(2)),
			}));
			setDataMaturity(parseScoreMaturityRating);
		}
	}, [data]);

	return (
		<div className="flex-1 flex flex-col">
			<Table columns={MATURITY_RATING_COLUMNS} data={dataMaturity} />
			<div className="mt-5 text-black font-medium flex flex-col justify-center border-[1px] border-black px-2 py-1">
				<p className="">
					<span className="pr-4">Maturity Rating:</span>
					{dataMaturity.reduce((sum, level) => sum + level.score, 0).toFixed(2)}
				</p>
				<p className="text-black">Rating is on a 0-5 scale.</p>
			</div>
		</div>
	);
};
