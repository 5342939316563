import { ApiRequestConfig, apiService } from './api.service';

import { IOrganization } from '@/interfaces/organization/IOrganization';
import { IApiService } from '@/interfaces/services/IApiService';
import { IOrganizationService } from '@/interfaces/services/IOrganizationService';

class OrganizationService implements IOrganizationService {
	api: IApiService<ApiRequestConfig>;
	constructor(api: IApiService<ApiRequestConfig>) {
		this.api = api;
	}

	async getAllOrganizations(
		config?: ApiRequestConfig,
	): Promise<IOrganization[]> {
		return await this.api.get<IOrganization[]>('/organization', config);
	}

	async getAllOrganizationsByUser(
		config?: ApiRequestConfig,
	): Promise<IOrganization[]> {
		return await this.api.get<IOrganization[]>('/organization/user', config);
	}

	async getOrganizationById(
		id: number,
		config?: ApiRequestConfig,
	): Promise<IOrganization> {
		return await this.api.get<IOrganization>(`/organization/${id}`, config);
	}

	async createOrganization(
		name: string,
		description: string,
		config?: ApiRequestConfig,
	): Promise<IOrganization> {
		return await this.api.post<IOrganization>(
			'/organization',
			{ name, description },
			config,
		);
	}

	async deleteOrganization(
		id: number,
		config?: ApiRequestConfig,
	): Promise<void> {
		return await this.api.delete(`/organization/${id}`, config);
	}
}

export const organizationService = new OrganizationService(apiService);
