/* eslint-disable prettier/prettier */
import { ApiRequestConfig, apiService } from './api.service';

import { IControl } from '@/interfaces/controls/controls';
import { IApiService } from '@/interfaces/services/IApiService';
import { IControlService } from '@/interfaces/services/IControlService';

class ControlService implements IControlService {
	api: IApiService<ApiRequestConfig>;
	constructor(api: IApiService<ApiRequestConfig>) {
		this.api = api;
	}

	async getControls(config?: ApiRequestConfig): Promise<IControl[]> {
		return await this.api.get<IControl[]>('/control', config);
	}

	async getControlById(
		id: number,
		config?: ApiRequestConfig,
	): Promise<IControl> {
		return await this.api.get<IControl>(`/control/${id}`, config);
	}
}

export const controlService = new ControlService(apiService);
