import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuthProvider } from '@/hooks/auth/useAuthProvider';
import { cookieService } from '@/services/cookie.service';

export default function SignOutRedirect() {
	const { handleSignOut } = useAuthProvider();

	useEffect(() => {
		cookieService.removeAll();
		handleSignOut();
	}, [handleSignOut]);
	return (
		<div className="bg-bigger w-full h-full">
			<Navigate to="/auth/sign-in" />;
		</div>
	);
}
