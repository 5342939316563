import { MATURITY_RATING_DEFAULT } from '@/data/table/maturityRatingData';
import { IGlobalRisk } from '@/interfaces/risk/IRisk';
import { IMaturityRating } from '@/interfaces/table/IMaturityRating';

export const calculateLevelTwoScore = (data: IGlobalRisk) =>
	Number(
		(
			data.globalControlsImplementation
				.filter((control) => control.id > 0 && control.id <= 5)
				.reduce((sum, control) => sum + control.average, 0) / 5
		).toFixed(2),
	);

export const setMaturityRatingData = (data: IGlobalRisk): IMaturityRating[] => {
	const maturityLevelsMap = {
		'Level One': data.globalPolicy,
		'Level Two': calculateLevelTwoScore(data),
		'Level Three': data.globalImplementation,
		'Level Four': data.globalAutomation,
		'Level Five': data.globalReporting,
	};

	return MATURITY_RATING_DEFAULT.map((maturityLevel) => ({
		...maturityLevel,
		score: maturityLevelsMap[maturityLevel.level] || 0,
	}));
};
