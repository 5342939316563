import { IColumn } from '@/interfaces/table/IAttckCapability';

interface IProps<T> {
	columns: IColumn<T>[];
	data: T[];
	getBackgroundColor?: (item: T, accessor: keyof T) => string;
}

const Table = <T,>({ columns, data, getBackgroundColor }: IProps<T>) => {
	return (
		<div className="flex relative overflow-x-auto" data-test="common-table">
			<table className="flex-1 ">
				<thead>
					<tr>
						{columns.map((col, colIndex) => (
							<th
								key={col.accessor as string}
								className={`border-[1px] border-black text-center text-sm font-bold text-black whitespace-nowrap py-1 ${
									colIndex === 0 ? 'px-20' : 'px-2'
								}`}
							>
								{col.header}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{data.map((item, rowIndex) => (
						<tr key={rowIndex}>
							{columns.map((col, colIndex) => {
								const value = item[col.accessor];
								const cellContent =
									typeof value === 'string' || typeof value === 'number'
										? value
										: JSON.stringify(value);

								return (
									<td
										key={col.accessor as string}
										className={`border-[1px] border-black text-center text-sm py-0.5 px-2 font-medium  ${
											getBackgroundColor
												? getBackgroundColor(item, col.accessor)
												: ''
										} ${
											colIndex === 0
												? 'border-[1px] border-[#ececec] text-black'
												: 'border-[1px] border-slate-200 bg-gray-200'
										}`}
									>
										{cellContent}
									</td>
								);
							})}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default Table;
