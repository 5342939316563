import React, { useEffect, useState } from 'react';

import Table from './Table';

import {
	ATTACK_CAPABILITY_COLUMS,
	ATTACK_CAPABILITY_DEFAULT,
} from '@/data/table/attckCapabilityData';
import { calculateAttckCapabilities } from '@/helpers/calculateAttckCapabilities';
import { IGlobalRisk } from '@/interfaces/risk/IRisk';
import {
	Capability,
	IAttackActivity,
} from '@/interfaces/table/IAttckCapability';

interface IProps {
	data: IGlobalRisk['globalControlsImplementation'];
}

export const AttckActivityTable: React.FC<IProps> = ({ data }) => {
	const [dataActivity, setDataActivity] = useState<IAttackActivity[]>(
		ATTACK_CAPABILITY_DEFAULT,
	);

	const getBackgroundColorForCapability = (
		item: IAttackActivity,
		accessor: keyof IAttackActivity,
	): string => {
		const value = item[accessor];
		switch (value) {
			case Capability.High:
				return 'bg-white text-[#70ad47]';
			case Capability.Moderate:
				return 'bg-white text-yellow-600';
			case Capability.Low:
				return 'bg-white text-[#a20000]';
			default:
				return '';
		}
	};

	const setAttackCapabilityData = () => {
		if (!data) return;

		const dataCapabilities = calculateAttckCapabilities(data);
		setDataActivity(dataCapabilities);
	};

	useEffect(() => {
		setAttackCapabilityData();
	}, [data]);

	return (
		<Table
			columns={ATTACK_CAPABILITY_COLUMS}
			data={dataActivity}
			getBackgroundColor={getBackgroundColorForCapability}
			data-test="attck-activity-table"
		/>
	);
};
