import React from 'react';

import { getPageNumbers } from '@/helpers/pagination';

interface PageListProps {
	totalPages: number;
	currentPage: number;
	ellipsisIndex: number | null;
	handleClickEllipsis: (index: number) => void;
	popupPages: number[];
	handlePageClick: (page: number) => void;
	control?: number;
}

const PageList: React.FC<PageListProps> = ({
	totalPages,
	currentPage,
	ellipsisIndex,
	popupPages,
	handlePageClick,
	handleClickEllipsis,
	control,
}) => {
	return (
		<ul className="flex w-full justify-center border-t">
			{getPageNumbers(totalPages, currentPage).map((page, index) => (
				<li key={index}>
					{page === '...' ? (
						<div className="relative">
							<button
								className="relative group px-4 pt-4 cursor-pointer"
								onClick={() => handleClickEllipsis(index)}
								data-test={`button-elipsis-${index}`}
							>
								<div className="absolute top-0 left-0 -top-px h-[2px] w-full group-hover:bg-gray-300 bg-transparent"></div>
								{page}
							</button>
							{index && ellipsisIndex === index && (
								<div className="absolute top-full left-0 mt-2 bg-white border rounded shadow-lg z-10">
									<ul className="p-2" data-test="pagelist-popup-pages">
										{popupPages.map((page, index) => (
											<li key={index}>
												<button
													className={`block px-3 py-1 ${
														page === currentPage
															? 'bg-blue-500 text-white'
															: 'bg-white text-black'
													}`}
													onClick={() => handlePageClick(page)}
												>
													{control && control + '.'}
													{page}
												</button>
											</li>
										))}
									</ul>
								</div>
							)}
						</div>
					) : (
						<button
							className={`relative group px-4 pt-4 text-sm font-medium text-gray-500 hover:text-gray-700 ${
								page === currentPage ? 'text-indigo-600' : ''
							}`}
							onClick={() => handlePageClick(page)}
						>
							<div
								className={`absolute top-[-1px] left-0 h-[2px] w-full ${
									page === currentPage
										? 'bg-indigo-600'
										: 'group-hover:bg-gray-300 bg-transparent'
								}`}
							></div>
							{control && control + '.'}
							{page}
						</button>
					)}
				</li>
			))}
		</ul>
	);
};

export default PageList;
