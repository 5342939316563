export const policyOptions = [
	'No Policy',
	'Informal Policy',
	'Partial Written Policy',
	'Written Policy',
	'Approved Written Policy',
];

export const implementationOptions = [
	'Not Implemented',
	'Parts of Policy Implemented',
	'Implemented on Some Systems',
	'Implemented on Most Systems',
	'Implemented on All Systems',
];

export const automationOptions = [
	'Not Automated',
	'Parts of Policy Automated',
	'Automated on Some Systems',
	'Automated on Most Systems',
	'Automated on All Systems',
];

export const reportingOptions = [
	'Not Reported',
	'Parts of Policy Reported',
	'Reported on Some Systems',
	'Reported on Most Systems',
	'Reported on All Systems',
];
