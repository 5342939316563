import { MenuItem, Select } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { useEffect } from 'react';

import { FIELDS } from '../../pages/cis-controls/Field';

import {
	ControlForm,
	ControlType,
	ISubcontrolForm,
} from '@/interfaces/controls/controls';

interface ISubcontrolProps {
	subcontrol: ISubcontrolForm;
	setForm: React.Dispatch<React.SetStateAction<ControlForm[]>>;
}

const Subcontrol = ({ subcontrol, setForm }: ISubcontrolProps) => {
	const fields = FIELDS.filter((field) => {
		if (subcontrol.automationAndReportingNotApplicable) {
			return field.type !== 'automation' && field.type !== 'reporting';
		}
		return true;
	});

	const { values, setFieldValue } = useFormikContext<ControlForm>();

	const handleChange = (type: ControlType, id: number, value: string) => {
		const updatedSubcontrols = values.subcontrols.map((subcontrol) => {
			if (subcontrol.id === id) {
				return { ...subcontrol, [type]: value };
			}
			return subcontrol;
		});

		setFieldValue('subcontrols', updatedSubcontrols);
	};
	useEffect(() => {
		setForm([values]);
	}, [setForm, values]);

	return (
		<div className="flex flex-col w-full my-2 gap-y-2" data-test="subcontrol">
			{fields.map(({ type, title, options }) => (
				<div
					key={type}
					className="flex flex-col justify-between"
					data-test={`subcontrol-field-${subcontrol.index}`}
				>
					<p className="mb-2 text-gray-500">{title}</p>

					<Field
						name={`${type}-${subcontrol.index}`}
						data-test={`subcontrol-field-input`}
					>
						{() => (
							<Select
								key={`${type}-${subcontrol.index}`}
								value={subcontrol[type]}
								onChange={(event) => {
									handleChange(type, subcontrol.id, event.target.value);
								}}
								renderValue={(value) => <div>{value}</div>}
							>
								{options.map((option) => (
									<MenuItem key={option} value={option}>
										{option}
									</MenuItem>
								))}
							</Select>
						)}
					</Field>
				</div>
			))}
		</div>
	);
};

export default Subcontrol;
