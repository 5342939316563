import { PlusIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';

import { CreateOrganizationModal } from '@/components/dashboard/CreateOrganizationModal';
import OrganizationContainer from '@/components/dashboard/OrganizationContainer';
import {
	ICreateOrganization,
	IOrganization,
} from '@/interfaces/organization/IOrganization';
import { organizationService } from '@/services/organization.service';

import braces from '@assets/braces.svg';

export default function Dashboard() {
	const [organizations, setOrganizations] = useState<IOrganization[]>([]);
	const [error, setError] = useState<string | null>(null);
	const [loading, setLoading] = useState<boolean>(true);

	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleOpenModal = () => setIsModalOpen(true);
	const handleCloseModal = () => setIsModalOpen(false);

	const handleCreateOrganization = async ({
		name,
		description,
	}: ICreateOrganization) => {
		try {
			const newOrganization = await organizationService.createOrganization(
				name,
				description,
			);
			setOrganizations((prevOrganizations) => [
				...prevOrganizations,
				newOrganization,
			]);
			handleCloseModal();
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			setError(err.message || 'Failed to create organization');
		}
	};

	useEffect(() => {
		const fetchOrganizations = async () => {
			setLoading(true);
			try {
				const organizations = await organizationService.getAllOrganizations();
				setOrganizations(organizations);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				setError(err.message || 'Failed to fetch organizations');
			} finally {
				setLoading(false);
			}
		};

		fetchOrganizations();
	}, []);

	return (
		<div className="flex flex-col items-center h-screen w-full overflow-y-auto p-4 bg-white ">
			<div className=" flex flex-col items-center h-screen w-full overflow-y-auto p-4 bg-white animate-appear">
				<div className="flex max-w-screen-md my-4  ">
					<img
						src={braces}
						alt=""
						className="rotate-180 h-12 sm:h-20 md:h-32 text-black"
					/>
					<h1
						className="text-5xl sm:text-7xl md:text-9xl font-bold mb-4 font-bigger text-black px-2"
						data-test="dashboard-title"
					>
						Organizations
					</h1>
					<img src={braces} alt="" className="h-12 sm:h-20 md:h-32" />
				</div>
				<div
					className="flex flex-col items-center my-10"
					data-test="no-organizations"
				>
					<button
						className="w-48 flex bg-bigger items-center p-2 font-bold text-white hover:bg-biggerHover"
						onClick={handleOpenModal}
						data-test="no-organization-add-button"
					>
						<PlusIcon className="h-4 w-4 mr-2" /> New Organization
					</button>
				</div>
				{organizations && organizations.length > 0 && (
					<OrganizationContainer organizations={organizations} />
				)}
				{error && <div className="text-red-500">{error}</div>}
				<CreateOrganizationModal
					isOpen={isModalOpen}
					onClose={handleCloseModal}
					onSubmit={handleCreateOrganization}
				/>
				{loading && (
					<p className="text-3xl font-bold mb-4 font-bigger text-black">
						Loading organizations...
					</p>
				)}
			</div>
		</div>
	);
}
