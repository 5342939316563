import {
	Capability,
	IAttackActivity,
	IColumn,
} from '@/interfaces/table/IAttckCapability';

export const ATTACK_CAPABILITY_DEFAULT: IAttackActivity[] = [
	{
		activity: 'Initial Access',
		preventive: Capability.Low,
		detective: Capability.Low,
	},
	{
		activity: 'Execution',
		preventive: Capability.Low,
		detective: Capability.Low,
	},
	{
		activity: 'Persistence',
		preventive: Capability.Low,
		detective: Capability.Low,
	},
	{
		activity: 'Privilege Escalation',
		preventive: Capability.Low,
		detective: Capability.Low,
	},
	{
		activity: 'Defense Evasion',
		preventive: Capability.Low,
		detective: Capability.Low,
	},
	{
		activity: 'Credential Access',
		preventive: Capability.Low,
		detective: Capability.Low,
	},
	{
		activity: 'Discovery',
		preventive: Capability.Low,
		detective: Capability.Low,
	},
	{
		activity: 'Lateral Movement',
		preventive: Capability.Low,
		detective: Capability.Low,
	},
	{
		activity: 'Collection',
		preventive: Capability.Low,
		detective: Capability.Low,
	},
	{
		activity: 'Command and Control',
		preventive: Capability.Low,
		detective: Capability.Low,
	},
	{
		activity: 'Exfiltration',
		preventive: Capability.Low,
		detective: Capability.Low,
	},
];

export const ATTACK_CAPABILITY_COLUMS: IColumn<IAttackActivity>[] = [
	{ header: 'ATT&CK Activity', accessor: 'activity' },
	{ header: 'Preventive Capability', accessor: 'preventive' },
	{ header: 'Detective Capability', accessor: 'detective' },
];
