export enum Capability {
	Low = 'Low',
	Moderate = 'Moderate',
	High = 'High',
	Invalid = 'INVALID',
}

export interface IAttackActivity {
	activity: string;
	preventive: Capability;
	detective: Capability;
}

export interface IColumnAttckActivity {
	accessor: keyof IAttackActivity;
	header: string;
	width?: string;
}

export interface IColumn<T> {
	accessor: keyof T;
	header: string;
	width?: string;
}
