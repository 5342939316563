import { ChartData } from 'chart.js';
import React, { useEffect, useState } from 'react';

import { BarChartComponent } from './barChartComponent';

import { IMaturityRating } from '@/interfaces/table/IMaturityRating';

interface IMaturityRatingChartProps {
	maturityRating: IMaturityRating[] | null;
}

const controlLabels = [
	'Policies Complete',
	'Controls 1-5 Implemented',
	'All controls Implemented',
	'All controls Automated',
	'All controls Reported',
];

export const MaturityRatingChart: React.FC<IMaturityRatingChartProps> = ({
	maturityRating,
}) => {
	const [maturityRatingData, setMaturityRatingData] = useState<ChartData<
		'bar',
		(number | null)[],
		unknown
	> | null>(null);

	useEffect(() => {
		if (!maturityRating) return;

		const controlPercents: (number | null)[] = new Array(5).fill(null);

		maturityRating.forEach((item, index) => {
			controlPercents[index] = item.score;
		});

		const chartData = {
			labels: controlLabels,
			datasets: [
				{
					data: controlPercents,
					backgroundColor: '#70AD47',
					borderColor: '#70AD47',
					borderWidth: 1,
				},
			],
		};
		setMaturityRatingData(chartData);
	}, [maturityRating]);

	const defaultData: ChartData<'bar', (number | null)[], unknown> = {
		labels: [],
		datasets: [
			{
				data: [],
				backgroundColor: [],
			},
		],
	};

	return (
		<div
			className="my-8 border border-black flex flex-col items-center w-fit"
			data-test="maturity-score-bar-chart"
		>
			<p className="w-full text-center font-bold text-xl pt-4 text-black">
				Maturity Level Aggregate Scores
			</p>
			<div className="p-8">
				<BarChartComponent
					data={maturityRatingData || defaultData}
					width={660}
					id="maturity-score-bar-chart"
				/>
			</div>
		</div>
	);
};
