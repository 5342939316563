import React from 'react';

import Card from './Card';

import { IControl } from '@/interfaces/controls/controls';

type CardContainerProps = {
	controls: IControl[];
};

const CardContainer: React.FC<CardContainerProps> = ({ controls }) => {
	return (
		<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 max-w-full mx-auto p-4 animate-appear ">
			{controls?.map((subcontrols, idx) => (
				<Card key={idx} controlNumber={idx + 1} name={subcontrols.name} />
			))}
		</div>
	);
};

export default CardContainer;
