import {
	automationOptions,
	implementationOptions,
	policyOptions,
	reportingOptions,
} from '@/context/option-messages';

enum FieldType {
	Policy = 'policy',
	Implementation = 'implementation',
	Automation = 'automation',
	Reporting = 'reporting',
}

interface IField {
	type: FieldType;
	title: string;
	options: string[];
}

export const FIELDS: IField[] = [
	{
		type: FieldType.Policy,
		title: 'Policy defined',
		options: policyOptions,
	},
	{
		type: FieldType.Implementation,
		title: 'Control implemented',
		options: implementationOptions,
	},
	{
		type: FieldType.Automation,
		title: 'Control Automated or Technically Enforced',
		options: automationOptions,
	},
	{
		type: FieldType.Reporting,
		title: 'Control Reported to Business',
		options: reportingOptions,
	},
];
