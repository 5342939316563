import {
	Dialog,
	DialogBackdrop,
	DialogPanel,
	DialogTitle,
} from '@headlessui/react';
import { PlusCircleIcon } from '@heroicons/react/24/solid';
import { ErrorMessage, Field, Form, Formik } from 'formik';

import { createOrganizationSchema } from './schemas/create-organization-schema';

interface CreateOrganizationModalProps {
	isOpen: boolean;
	onClose: () => void;
	onSubmit: (organization: { name: string; description: string }) => void;
}

export const CreateOrganizationModal = ({
	isOpen,
	onClose,
	onSubmit,
}: CreateOrganizationModalProps) => {
	if (!isOpen) return null;

	const initialValues = {
		name: '',
		description: '',
	};

	const handleSubmit = (values: { name: string; description: string }) => {
		onSubmit(values);
		onClose();
	};

	return (
		<Dialog open={isOpen} onClose={onClose} className="relative z-10">
			<DialogBackdrop
				transition
				className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
			/>

			<div
				className="fixed inset-0 z-10 w-screen overflow-y-auto"
				data-test="create-organization-modal"
			>
				<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
					<DialogPanel
						transition
						className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
					>
						<div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
							<div className="sm:flex sm:items-start">
								<div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
									<PlusCircleIcon
										aria-hidden="true"
										className="h-6 w-6 text-blue-600"
									/>
								</div>
								<div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
									<DialogTitle
										as="h3"
										className="text-base font-semibold leading-6 text-gray-900"
										data-test="create-organization-modal-title"
									>
										Create Organization
									</DialogTitle>
									<div className="mt-2">
										<Formik
											initialValues={initialValues}
											validationSchema={createOrganizationSchema}
											onSubmit={handleSubmit}
										>
											{({ isSubmitting }) => (
												<Form>
													<div className="mb-4">
														<label
															className="block text-gray-700 text-sm font-bold mb-2"
															htmlFor="name"
														>
															Name
														</label>
														<Field
															id="name"
															name="name"
															type="text"
															className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
															data-test="create-organization-name-input"
														/>
														<ErrorMessage
															name="name"
															component="div"
															className="text-red-500 text-sm mt-1"
															data-test="create-organization-name-error"
														/>
													</div>
													<div className="mb-4">
														<label
															className="block text-gray-700 text-sm font-bold mb-2"
															htmlFor="description"
														>
															Description
														</label>
														<Field
															id="description"
															name="description"
															type="text"
															className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
															data-test="create-organization-description-input"
														/>
														<ErrorMessage
															name="description"
															component="div"
															className="text-red-500 text-sm mt-1"
															data-test="create-organization-description-error"
														/>
													</div>
													<div className="bg-gray-50 py-3">
														<button
															type="submit"
															disabled={isSubmitting}
															className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto mr-4"
															data-test="create-organization-modal-submit-button"
														>
															Create
														</button>
														<button
															type="button"
															data-autofocus
															onClick={onClose}
															className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
															data-test="create-organization-modal-cancel-button"
														>
															Cancel
														</button>
													</div>
												</Form>
											)}
										</Formik>
									</div>
								</div>
							</div>
						</div>
					</DialogPanel>
				</div>
			</div>
		</Dialog>
	);
};
