import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

import { useAuthProvider } from '@/hooks/auth/useAuthProvider';
import { IReactChildrenProps } from '@/interfaces/IReactChildren';
import { StoredCookies } from '@/interfaces/auth/cookies.enum';

export default function RequireAuth({ children }: IReactChildrenProps) {
	const { handleRefreshSession, loadingState } = useAuthProvider();
	const navigate = useNavigate();
	const [cookies] = useCookies([
		StoredCookies.USERNAME,
		StoredCookies.REFRESH_TOKEN,
	]);
	const connected =
		!!cookies[StoredCookies.REFRESH_TOKEN] && !!cookies[StoredCookies.USERNAME];

	useEffect(() => {
		if (!connected) {
			navigate('/auth/sign-in');
		}
	}, [connected]);

	useEffect(() => {
		handleRefreshSession();
	}, [handleRefreshSession]);

	if (loadingState.refreshSession)
		return (
			<div className="flex flex-1 items-center justify-center flex-col">
				<div className="flex flex-1 items-center justify-center">
					<span className="material-symbols-outlined animate-spin pointer-events-none">
						progress_activity
					</span>
				</div>
			</div>
		);
	else return <>{children}</>;
}
