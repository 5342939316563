import React, { useState } from 'react';

import { PaginationControl } from './PaginationControl';

import PageList from '@/components/pagination/PageList';
import { getEllipsisRange, getPageNumbers } from '@/helpers/pagination';

interface IProps {
	totalPages: number;
	handleChangePage: (page: number) => void;
	handleNextPage: () => void;
	handlePreviousPage: () => void;
	currentPage: number;
	control?: number;
}

const Pagination: React.FC<IProps> = ({
	totalPages,
	handleChangePage,
	handleNextPage,
	handlePreviousPage,
	currentPage,
	control,
}) => {
	const [ellipsisIndex, setEllipsisIndex] = useState<number | null>(null);
	const [popupPages, setPopupPages] = useState<number[]>([]);

	const handlePageClick = (page: number) => {
		if (page > totalPages || page < 1) return;
		handleChangePage(page);
		setEllipsisIndex(null);
	};

	const changePopupPages = (start: number, end: number, key: number) => {
		const pages: number[] = [];
		for (let i = start; i <= end; i++) {
			pages.push(i);
		}
		setPopupPages(pages);
		setEllipsisIndex(key);
	};

	const handleClickEllipsis = (index: number) => {
		if (ellipsisIndex === index) {
			setEllipsisIndex(null);
			return;
		}
		const ellipsisType = index === 1 ? 'first' : 'second';
		const range = getEllipsisRange(
			getPageNumbers(totalPages, currentPage),
			ellipsisType,
		);

		changePopupPages(range.start, range.end, index);
	};

	return (
		<div className="flex justify-between items-start" data-test="pagination">
			<PaginationControl
				type="previous"
				onClick={() => {
					handlePreviousPage();
					setEllipsisIndex(null);
				}}
			/>
			<PageList
				totalPages={totalPages}
				currentPage={currentPage}
				ellipsisIndex={ellipsisIndex}
				popupPages={popupPages}
				handlePageClick={handlePageClick}
				handleClickEllipsis={handleClickEllipsis}
				control={control}
			/>
			<PaginationControl
				type="next"
				onClick={() => {
					handleNextPage();
					setEllipsisIndex(null);
				}}
			/>
		</div>
	);
};

export default Pagination;
