import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { Button } from '@mui/material';
import { Form, Formik } from 'formik';
import {
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useState,
} from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import Pagination from '../../components/pagination/Pagination';

import { ChartRisk } from '@/components/chart/ChartRisk';
import Subcontrol from '@/components/cis-controls/Subcontrol';
import {
	ControlForm,
	IControl,
	ISubcontrol,
} from '@/interfaces/controls/controls';
import { Form as IForm } from '@/interfaces/forms/Iforms';
import { fromService } from '@/services/form.service';
import { notificationService } from '@/services/notification.service';
import { riskService } from '@/services/risk.service';
import { MAX_CONTROLS } from '@/utils/constants';
import {
	handleDeformatResponse,
	handleFormatResponse,
} from '@/utils/functions';

type ControlProps = {
	selectedControl: IControl;
};

const RISK_DEFAULT = {
	addressed: 0,
	accepted: 100,
};

type Step = {
	id: string;
	name: string;
	status: 'current' | 'upcoming';
};

const Control: React.FC<ControlProps> = ({ selectedControl }) => {
	const index = Number(selectedControl?.id);
	const isValidIndex =
		Number.isInteger(index) && index >= 1 && index <= MAX_CONTROLS;

	const [currentStep, setCurrentStep] = useState(0);
	const [form, setForm] = useState<ControlForm[]>([]);
	const [forms, setForms] = useState<IForm[]>([]);
	const [error, setError] = useState(null);
	const [risk, setRisk] = useState({} as typeof RISK_DEFAULT);
	const [emptyRisk, setEmptyRisk] = useState(true);
	const [errorRisk, setErrorRisk] = useState<string | null>(null);

	const organizationId = useParams<{ organizationId: string }>().organizationId;

	const navigate = useNavigate();

	useLayoutEffect(() => {
		setCurrentStep(0);
	}, [selectedControl]);

	const getControlForms = useCallback<() => void>(async () => {
		if (!organizationId) return;
		const forms = await fromService.getAllByOrganizationId(+organizationId, {
			params: { controlId: index },
		});
		setForms(forms);
	}, [organizationId, index]);

	const getControlRisk = useCallback<() => void>(async () => {
		if (!organizationId || !selectedControl?.id) return;
		setErrorRisk(null);

		try {
			const riskControl = await riskService.getControlRisk(
				+organizationId,
				index,
			);
			const riskData = riskControl.controlsImplementationAverage.length
				? {
						accepted: riskControl.controlRiskAccepted,
						addressed: riskControl.controlRiskAddressed,
				  }
				: RISK_DEFAULT;

			setEmptyRisk(!riskControl.controlsImplementationAverage.length);
			setRisk(riskData);

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setEmptyRisk(true);
			setRisk(RISK_DEFAULT);
			setErrorRisk(error.message || 'Failed to fetch control risk');
		}
	}, [index, organizationId, selectedControl?.id]);

	useEffect(() => {
		getControlForms();
	}, [getControlForms]);

	useEffect(() => {
		getControlRisk();
	}, [index, getControlRisk]);

	const subcontrols = useMemo(
		() => selectedControl?.details || [],
		[selectedControl],
	);

	const subcontrolsValues: ISubcontrol[] = useMemo(
		() =>
			new Array(subcontrols.length)
				.fill({
					policy: handleDeformatResponse(
						forms.find(
							(form) => form.detail.id === subcontrols[currentStep]?.id,
						)?.answer?.policyDefined || 'No policy',
					),
					implementation: handleDeformatResponse(
						forms.find(
							(form) => form.detail.id === subcontrols[currentStep]?.id,
						)?.answer?.controlImplemented || 'Not Implemented',
					),
					automation: handleDeformatResponse(
						forms.find(
							(form) => form.detail.id === subcontrols[currentStep]?.id,
						)?.answer?.controlAutomated || 'Not Automated',
					),
					reporting: handleDeformatResponse(
						forms.find(
							(form) => form.detail.id === subcontrols[currentStep]?.id,
						)?.answer?.controlReported || 'Not Reported',
					),
				})
				.map((subcontrol, index) => {
					const start = 1;
					return {
						...subcontrol,
						id: index + start,
						...subcontrols[index],
					};
				}),
		[subcontrols, forms, currentStep],
	);

	if (!isValidIndex) return <Navigate to="/" />;

	if (!organizationId) return <Navigate to="/" />;

	const steps: Step[] = subcontrolsValues.map((_subcontrol, idx) => ({
		id: `${idx + 1}`,
		name: `Step ${idx + 1}`,
		status: idx === currentStep ? 'current' : 'upcoming',
	}));

	const handleNextStep = () => {
		if (currentStep < subcontrolsValues.length - 1) {
			setCurrentStep(currentStep + 1);
		} else if (index < MAX_CONTROLS) {
			navigate(`/organization/${organizationId}/cis-controls/${index + 1}`);
		} else {
			navigate(`/`);
		}
	};

	const handlePreviousStep = () => {
		if (currentStep > 0) {
			setCurrentStep(currentStep - 1);
		} else if (index > 1) {
			navigate(`/organization/${organizationId}/cis-controls/${index - 1}`);
		}
	};

	const handleStepClick = (stepIndex: number) => {
		setCurrentStep(stepIndex - 1);
	};

	const handleSubmit = async () => {
		setError(null);
		const formattedData = {
			organizationId: +organizationId,
			detailId: form[0].subcontrols[currentStep]?.id,
			policyDefined: handleFormatResponse(
				form[0].subcontrols[currentStep].policy,
			),
			controlImplemented: handleFormatResponse(
				form[0].subcontrols[currentStep].implementation,
			),
			controlAutomated: handleFormatResponse(
				form[0].subcontrols[currentStep].automation,
			),
			controlReported: handleFormatResponse(
				form[0].subcontrols[currentStep].reporting,
			),
		};

		const formExistsForCurrentStep = forms.find(
			(form) => form.detail.id === subcontrols[currentStep]?.id,
		);

		if (formExistsForCurrentStep) {
			try {
				await fromService.updateForm(
					formExistsForCurrentStep.id,
					formattedData,
				);
				getControlRisk();
				notificationService.success('Form Updated Successfully');

				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				setError(error.message || 'Error to Updated Form');
			}
		} else {
			try {
				await fromService.postForm(formattedData);
				getControlRisk();
				notificationService.success('Form Updated Successfully');

				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				setError(error.message || 'Error to Updated Form');
			}
		}

		getControlForms();
	};

	const handlePreviousControl = () => {
		if (index > 1) {
			navigate(`/organization/${organizationId}/cis-controls/${index - 1}`);
		}
	};

	const handleNextControl = () => {
		if (index < MAX_CONTROLS) {
			navigate(`/organization/${organizationId}/cis-controls/${index + 1}`);
		}
	};

	const buttonText = forms?.some(
		(value) => subcontrols[currentStep]?.id === value.detail.id,
	)
		? 'Update'
		: 'Save';

	const handleNavigateControls = () => {
		navigate(`/organization/${organizationId}`);
	};

	return (
		<div className="flex items-center justify-center flex-col">
			<div className="w-3/4">
				<div className="flex mt-8 justify-between items-center">
					<div className="flex items-center">
						<Button onClick={handlePreviousControl} disabled={index === 1}>
							<ChevronLeftIcon />
						</Button>
						<h2 className="text-3xl" data-test="control-number">
							Control #{selectedControl?.id}
						</h2>
						<Button
							onClick={handleNextControl}
							disabled={index === MAX_CONTROLS}
							data-test="next-button-control"
						>
							<ChevronRightIcon />
						</Button>
					</div>
					<div>
						<button
							onClick={handleNavigateControls}
							className="text-black hover:bg-gray-200 font-semibold py-4 px-4 border border-black "
						>
							Show Controls
						</button>
					</div>
				</div>
				<h1 className="text-3xl ml-10" data-test="control-title">
					{selectedControl?.name}
				</h1>
				<ChartRisk risk={risk} emptyRisk={emptyRisk} error={errorRisk} />
				<Pagination
					control={index}
					totalPages={steps.length}
					currentPage={currentStep + 1}
					handleChangePage={handleStepClick}
					handleNextPage={handleNextStep}
					handlePreviousPage={handlePreviousStep}
				/>
				<div className="my-8 p-8 border rounded shadow-lg">
					<div className="flex flex-1 items-center justify-center flex-col gap-y-4">
						<Formik
							initialValues={{
								subcontrols: subcontrolsValues,
							}}
							onSubmit={handleSubmit}
							enableReinitialize={true}
						>
							{({ values }) => (
								<Form className="w-full">
									<h5
										className="text-xl mb-4"
										data-test="subcontrol-description-subtitle"
									>
										Detail:
									</h5>
									<p data-test="subcontrol-detail" className="mb-8">
										{values.subcontrols[currentStep].cisControlDetail}
									</p>
									<Subcontrol
										key={values.subcontrols[currentStep].index}
										subcontrol={values.subcontrols[currentStep]}
										setForm={setForm}
										data-test="subcontrol-form"
									/>
								</Form>
							)}
						</Formik>
					</div>

					{error && <p className="text-red-500 text-right">{error}</p>}
					<div className="py-4 flex justify-between">
						<div className="space-x-4">
							<Button
								variant="contained"
								onClick={() => {
									handlePreviousStep();
									window.scrollTo(0, 0);
								}}
								disabled={currentStep === 0}
								data-test="back-button"
							>
								Back
							</Button>

							<Button
								variant="contained"
								onClick={() => {
									handleNextStep();
									window.scrollTo(0, 0);
								}}
								data-test="next-button"
								type="submit"
							>
								{index === MAX_CONTROLS &&
								currentStep === subcontrolsValues.length - 1
									? 'Finish'
									: 'Next'}
							</Button>
						</div>

						<div>
							<Button
								variant="contained"
								onClick={() => {
									handleSubmit();
								}}
								data-test="save-button"
							>
								{buttonText}
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Control;
