import SignInForm from '@/components/auth/SignInForm';
import { useAuthProvider } from '@/hooks/auth/useAuthProvider';

export default function SignIn() {
	const { handleSignIn, loadingState } = useAuthProvider();
	return (
		<div className="flex-1 bg-white">
			<div className="animate-appear">
				<SignInForm handleSubmit={handleSignIn} loading={loadingState.signIn} />
			</div>
		</div>
	);
}
