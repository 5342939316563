import * as Yup from 'yup';

import {
	DESCRIPTION_REQUIRED,
	NAME_REQUIRED,
} from './organization-schema-errors';

export const createOrganizationSchema = Yup.object({
	name: Yup.string().required(NAME_REQUIRED),
	description: Yup.string().required(DESCRIPTION_REQUIRED),
});
