import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ImpGroupsBarChart } from '@/components/chart/ImpGroupsBarChart';
import { MaturityRatingChart } from '@/components/chart/MaturityRatingChart';
import { ImpPercentageChart } from '@/components/chart/impPercentageChart';
import CardContainer from '@/components/dashboard/CardContainer';
import { DeleteOrganizationModal } from '@/components/dashboard/DeleteOrganizationModal';
import { AttckActivityTable } from '@/components/table/AttckActivityTable';
import { MaturityRatingTable } from '@/components/table/MaturityRatingTable';
import { MATURITY_RATING_DEFAULT } from '@/data/table/maturityRatingData';
import { calculateLevelTwoScore } from '@/helpers/maturityRatingUtils';
import { IControl } from '@/interfaces/controls/controls';
import { IGlobalRisk } from '@/interfaces/risk/IRisk';
import { IMaturityRating } from '@/interfaces/table/IMaturityRating';
import { controlService } from '@/services/control.service';
import { notificationService } from '@/services/notification.service';
import { organizationService } from '@/services/organization.service';
import { riskService } from '@/services/risk.service';

import braces from '@assets/braces.svg';

const IMPLEMENTATION_GROUPS_DEFAULT = {
	one: 0,
	two: 0,
	three: 0,
};

export default function Organization() {
	const navigate = useNavigate();
	const [controls, setControls] = useState<IControl[]>([]);
	const [showDashboard, setShowDashboard] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [implementationGroups, setImplementationGroups] = useState(
		{} as typeof IMPLEMENTATION_GROUPS_DEFAULT,
	);
	const [implementationPercent, setImplementationPercent] = useState<
		{ id: number; average: number }[]
	>([]);
	const organizationId = useParams<{ organizationId: string }>().organizationId;
	const [maturityRating, setMaturityRating] = useState<
		IMaturityRating[] | null
	>(null);

	const setMaturityRatingData = (data: IGlobalRisk) => {
		const maturityLevelsMap = {
			'Level One': data.globalPolicy,
			'Level Two': calculateLevelTwoScore(data),
			'Level Three': data.globalImplementation,
			'Level Four': data.globalAutomation,
			'Level Five': data.globalReporting,
		};

		return MATURITY_RATING_DEFAULT.map((maturityLevel) => ({
			...maturityLevel,
			score: maturityLevelsMap[maturityLevel.level] || 0,
		}));
	};

	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleOpenModal = () => setIsModalOpen(true);
	const handleCloseModal = () => setIsModalOpen(false);

	const getOrganizationRisk = useCallback<() => void>(async () => {
		if (!organizationId) return;

		try {
			const riskGlobal = await riskService.getGlobalRisk(+organizationId);

			const maturityRatingData = setMaturityRatingData(riskGlobal);
			setMaturityRating(maturityRatingData);
			setImplementationGroups({
				one: riskGlobal.globalImplementationGroupOne,
				two: riskGlobal.globalImplementationGroupTwo,
				three: riskGlobal.globalImplementationGroupThree,
			});
			setImplementationPercent(riskGlobal.globalControlsImplementation);

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setImplementationGroups(IMPLEMENTATION_GROUPS_DEFAULT);
		}
	}, [organizationId]);

	useEffect(() => {
		getOrganizationRisk();
	}, [getOrganizationRisk]);

	const handleRemoveOrganization = async () => {
		try {
			await organizationService.deleteOrganization(Number(organizationId));
			notificationService.success('Organization Delete');
			navigate('/');

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setIsModalOpen(false);
			notificationService.error(
				error.message || 'Error to delete organization',
			);
		}
	};

	useEffect(() => {
		const fetchControls = async () => {
			try {
				const control = await controlService.getControls();
				setControls(control);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				setError(err.message || 'Failed to fetch controls');
			}
		};

		fetchControls();
	}, []);

	return (
		<div className="items-center flex flex-col w-full h-full p-4 bg-white">
			<div className="items-center flex flex-col w-full h-full p-4 bg-white animate-appear">
				<div className="flex max-w-screen-md my-4">
					<img
						src={braces}
						alt=""
						className="rotate-180 h-12 sm:h-20 md:h-32"
					/>
					<h1 className="text-5xl sm:text-7xl md:text-9xl font-bold mb-4 font-bigger text-black px-2">
						CONTROLS
					</h1>
					<img src={braces} alt="" className="h-12 sm:h-20 md:h-32" />
				</div>
				<div className="flex gap-4 py-4">
					<button
						onClick={() => setShowDashboard(false)}
						className="text-black hover:bg-gray-200 font-semibold py-4 px-4 border border-black "
					>
						Show Controls
					</button>
					<button
						onClick={() => setShowDashboard(true)}
						className="text-black hover:bg-gray-200 font-semibold py-4 px-4 border border-black "
						data-test="show-dashboard"
					>
						Show Dashboard
					</button>
					<button
						data-test="button-delete"
						onClick={handleOpenModal}
						className="bg-red-600 text-white hover:bg-red-400  font-semibold hover:text-white py-2 px-4 border border-red-600 hover:border-transparent rounded"
					>
						Delete organization
					</button>
				</div>
				{showDashboard && (
					<div className="flex flex-col gap-4 xl:flex-row items-center animate-appear">
						<div className="flex flex-col max-w-xl">
							<AttckActivityTable data={implementationPercent} />
							<ImpGroupsBarChart implementationGroups={implementationGroups} />
							<MaturityRatingTable data={maturityRating} />
						</div>
						<div className="flex flex-col flex-1">
							<MaturityRatingChart maturityRating={maturityRating} />
							<ImpPercentageChart
								implementationPercents={implementationPercent}
							/>
						</div>
					</div>
				)}
				{!showDashboard && <CardContainer controls={controls} />}

				{error && <div className="text-red-500">{error}</div>}
				<DeleteOrganizationModal
					isOpen={isModalOpen}
					onClose={handleCloseModal}
					onDelete={handleRemoveOrganization}
				/>
			</div>
		</div>
	);
}
